export const enum UserProfileTrackingProperties {
  AccommodationsIds = 'accommodations_ids',
  AccommodationsNames = 'accommodations_names',
  MarketOccupancyAvailable = 'market_occupancy_available',
  MarketOccupancyStatus = 'market_occupancy_status',
  MarketCorrectionAvailable = 'market_correction_available',
  MarketCorrectionEnabled = 'market_correction_enabled',
  AccommodationsPipelineVerions = 'accommodations_pipeline_versions',

  // Orphan Nights
  MinStayManagementAvailablePropertiesCount = 'Min stay management available properties count',
  GapFillingAvailablePropertiesCount = 'Gap filling available properties count',
  GapFillingEnabledPropertiesCount = 'Gap filling enabled properties count',
  MinStayEnabledPropertiesCount = 'Min stay enabled properties count',
  MinStaRuleEnabledPropertiesCount = 'Min stay rule enabled properties count',
}
