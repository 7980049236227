import revive_payload_client_tAlg1kNgrX from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_z5tbzttzbcmmibq67j53po5v6u/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_n0yFSy7F22 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_z5tbzttzbcmmibq67j53po5v6u/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vtM01aVHTy from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_z5tbzttzbcmmibq67j53po5v6u/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_A0SDWnatEk from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_z5tbzttzbcmmibq67j53po5v6u/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kCkcTx1QhI from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_z5tbzttzbcmmibq67j53po5v6u/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_NFN8GQkIOw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_z5tbzttzbcmmibq67j53po5v6u/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ITMo73qypk from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_z5tbzttzbcmmibq67j53po5v6u/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_901WyBJkZt from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.28.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_OyynZxTb3m from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_z5tbzttzbcmmibq67j53po5v6u/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_O2mLuCcwpP from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.0_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_LRensDRgjh from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.28.0_vue@3.5.13_typescript@5.7.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import errorHandling_EicKpRvXfW from "/opt/build/repo/src/plugins/errorHandling.ts";
import googleTagManager_LIIzgQRozu from "/opt/build/repo/src/plugins/googleTagManager.ts";
import pinia_cfuRVwzvtd from "/opt/build/repo/src/plugins/pinia.ts";
import sentry_h0fMjCG9AB from "/opt/build/repo/src/plugins/sentry.ts";
import tracking_tMIteM76jV from "/opt/build/repo/src/plugins/tracking.ts";
import v_calendar_iMjdyQNhyd from "/opt/build/repo/src/plugins/v-calendar.ts";
export default [
  revive_payload_client_tAlg1kNgrX,
  unhead_n0yFSy7F22,
  router_vtM01aVHTy,
  payload_client_A0SDWnatEk,
  navigation_repaint_client_kCkcTx1QhI,
  check_outdated_build_client_NFN8GQkIOw,
  chunk_reload_client_ITMo73qypk,
  plugin_vue3_901WyBJkZt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_OyynZxTb3m,
  switch_locale_path_ssr_O2mLuCcwpP,
  i18n_LRensDRgjh,
  errorHandling_EicKpRvXfW,
  googleTagManager_LIIzgQRozu,
  pinia_cfuRVwzvtd,
  sentry_h0fMjCG9AB,
  tracking_tMIteM76jV,
  v_calendar_iMjdyQNhyd
]